// Core variables and mixins

$sansFontFamily: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/variables"; // Modify this for custom colors, font-sizes, etc
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/mixins";

// CSS Reset
//@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/reset";

// Grid system and page structure
//@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/scaffolding";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/grid";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/layouts";

// Base CSS
//@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/type";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/code";
//@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/forms";
//@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/tables";

// Components: common
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/sprites";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/dropdowns";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/wells";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/component-animations";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/close";

// Components: Buttons & Alerts
//@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/buttons";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/button-groups";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/alerts"; // Note: alerts share common CSS with buttons and thus have styles in buttons

// Components: Nav
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/navs";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/navbar";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/pagination";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/pager";

// Components: Popovers
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/modals";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/tooltip";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/popovers";

// Components: Misc
//@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/thumbnails";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/media";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/labels-badges";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/progress-bars";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/accordion";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/carousel";
//@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/hero-unit";

// Utility classes
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/utilities"; // Has to be last to override when necessary

@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/responsive-utilities";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/responsive-1200px-min";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/responsive-768px-979px";
@import "../../vendor/bootstrap-sass-2.3.2.2/vendor/assets/stylesheets/bootstrap/responsive-navbar";

