//
// Layouts
// --------------------------------------------------


// Container (centered, fixed-width layouts)
.container {
  @include container-fixed();
}

// Fluid layouts (left aligned, with sidebar, min- & max-width content)
.container-fluid {
  padding-right: $gridGutterWidth;
  padding-left: $gridGutterWidth;
  @include clearfix();
}
