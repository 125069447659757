//
// Responsive: Tablet to desktop
// --------------------------------------------------


@media (min-width: 768px) and (max-width: 979px) {

  // Fixed grid
  @include grid-core($gridColumnWidth768, $gridGutterWidth768);

  // Fluid grid
  @include grid-fluid($fluidGridColumnWidth768, $fluidGridGutterWidth768);

  // Input grid
  @include grid-input($gridColumnWidth768, $gridGutterWidth768);

  // No need to reset .thumbnails here since it's the same $gridGutterWidth

}
