/**
 *
 * Main stylesheet for Powerange.
 * http://abpetkov.github.io/powerange/
 *
 */

/**
 * Horizontal slider style (default).
 */

.range-bar {
  background-color: #a9acb1;
  border-radius: 15px;
  display: block;
  height: 4px;
  position: relative;
  width: 100%;
}

.range-quantity {
  background-color: #017afd;
  border-radius: 15px;
  display: block;
  height: 100%;
  width: 0;
}

.range-handle {
  background-color: #fff;
  border-radius: 100%;
  cursor: move;
  height: 30px;
  left: 0;
  top: -13px;
  position: absolute;
  width: 30px;

  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.range-min,
.range-max {
  color: #181819;
  font-size: 12px;
  height: 20px;
  padding-top: 4px;
  position: absolute;
  text-align: center;
  top: -9px;
  width: 24px;
}

.range-min {
  left: -30px;
}

.range-max {
  right: -30px;
}

/**
 * Vertical slider style.
 */

.vertical {
  height: 100%;
  width: 4px;
}

.vertical .range-quantity {
  bottom: 0;
  height: 0;
  position: absolute;
  width: 100%;
}

.vertical .range-handle {
  bottom: 0;
  left: -13px;
  top: auto;
}

.vertical .range-min,
.vertical .range-max {
  left: -10px;
  right: auto;
  top: auto;
}

.vertical .range-min {
  bottom: -30px;
}

.vertical .range-max {
  top: -30px;
}

/**
 * Style for disabling text selection on handle move.
 */

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/**
 * Style for handle cursor on disabled slider.
 */

.range-disabled {
  cursor: default;
}

/* ================================================================================================================================================== */


/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
-moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
   its values is > 50%. */
  z-index: 10;
}
.noUi-stacking + .noUi-origin {
/* Fix stacking order in IE7, which incorrectly
   creates a new context for the origins. */
  *z-index: -1;
}
.noUi-state-tap .noUi-origin {
-webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 9px;
}
.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -11px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

/* Styling;
 */
.noUi-background {
  background: #FAFAFA;
  box-shadow: inset 0 1px 1px #f0f0f0;
}
.noUi-connect {
  background: #787878;
  box-shadow: inset 0 0 3px rgba(51,51,51,0.45);
-webkit-transition: background 450ms;
  transition: background 450ms;
}
.noUi-origin {
  border-radius: 2px;
}
.noUi-target {
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}
.noUi-target.noUi-connect {
  box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
}

/* Handles and cursors;
 */
.noUi-dragable {
  cursor: w-resize;
}
.noUi-vertical .noUi-dragable {
  cursor: n-resize;
}
.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF,
        inset 0 1px 7px #EBEBEB,
        0 3px 6px -3px #BBB;
}
.noUi-active {
  box-shadow: inset 0 0 1px #FFF,
        inset 0 1px 7px #DDD,
        0 3px 6px -3px #BBB;
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #787878;
  left: 14px;
  top: 6px;
}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled] .noUi-handle {
  cursor: not-allowed;
}

.slider-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  font: 400 12px/12px Arial;
  border-radius: 3px;
  background: #fff;
  top: -33px;
  padding: 5px;
  left: -9px;
  text-align: center;
  width: 50px;
}
.slider-tooltip strong {
  display: block;
  padding: 2px;
}

.slider-arrow-down {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-top: 5px solid black;
}

.slider-padding-list {
  padding-top: 35px;
}

.slider-padding {
  padding-top: 35px;
}

@media (max-width: 585px) {
  .slider-padding {
    padding-top: 5px;
  }
}

.slider-lesser-width {
  width: 55%;
}

.slider-padding-bottom {
  padding-bottom: 15px;
}

.slider-padding-right {
  padding-right: 30px;
}

.slider-padding-left {
  padding-left: 15px;
}