.icui {
  position: relative;

  input {
    height: 32px;
    width: 100%;
    max-width: 183px;
  }

  span.btn {
    padding: 0px 5px;
    font-size: 20px;
    margin-right: 4px;
    font-weight: 300;
    border-radius: 0;
    width: 25px;
    height: 32px;
    margin-left: 0px;
    text-align: center;
  }

  .clone {
    background: #DEF2FC;
    color: #3FACEF;
    border: solid #5dbded 1px;
    line-height: 1.5;
  }

  .destroy {
    background: rgb(247,213,208);
    color: rgb(206,89,69);
    border: solid rgb(206,89,69) 1px;
    line-height: 1.3;
  }

  .row {
    margin: 5px 0 !important;
  }

  .small-padding {
    padding: 0 2px 0 2px !important;
  }

  .big-input {

    select, input, .bootstrap-select {
      max-width: 100%;
    }

  }

  .label {
    padding: 0 5px 0 5px;
    text-align: left;
    line-height: 2;
    color: rgb(85, 85, 85);
    background: 0;
    font-size: 16px;
  }

  .bootstrap-select {
    margin-bottom: 0px !important;
    max-width: 183px;

    .selectpicker {
      border: 1px solid #ccc !important;
    }
  }
}

#list-space-flow-form {
  .icui {

    .row {
      max-width: 665px;
      display: flex;
    }

    .destroy {
      line-height: 1.5;
    }

    input {
      padding: 0;
      margin-bottom: 0;
      border-radius: 0;
      background-color: white;
      padding: 0 7px;
      color: rgb(120, 120, 120);
      border: 2px inset;
      max-width: 181px;
    }

    input[type='text'] {
      box-sizing: initial;
    }

    input[type='number'] {
      max-width: 199px;
    }

    select {
      width: 100%;
      height: 32px;
      border-radius: 0;
      padding: 0;
      margin: 0;
      background-color: white;
    }

    .row {
      margin-right: -15px;
      margin-left: -15px;
    }
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }

    @media (min-width: 992px) {
      .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left;
      }
      .col-md-12 {
        width: 100%;
      }
      .col-md-11 {
        width: 91.66666667%;
      }
      .col-md-10 {
        width: 83.33333333%;
      }
      .col-md-9 {
        width: 75%;
      }
      .col-md-8 {
        width: 66.66666667%;
      }
      .col-md-7 {
        width: 58.33333333%;
      }
      .col-md-6 {
        width: 50%;
      }
      .col-md-5 {
        width: 41.66666667%;
      }
      .col-md-4 {
        width: 33.33333333%;
      }
      .col-md-3 {
        width: 25%;
      }
      .col-md-2 {
        width: 16.66666667%;
      }
      .col-md-1 {
        width: 8.33333333%;
      }
    }
  }
}

