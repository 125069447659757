#taxonomy_tree, .category_tree, #category_tree {
  > ul, .jstree-icon {
    background-image: none !important;
  }

  .jstree-icon {
    @extend [class^="icon-"]:before !optional;
    @extend .fa !optional;
  }

  .jstree-open > .jstree-icon {
    @extend .fa-caret-down !optional;
  }
  .jstree-closed > .jstree-icon {
    @extend .fa-caret-right !optional;
  }

  li {
    background-image: none !important;

    a {
      background-color: very-light($gray80);
      border: 1px solid $gray210;
      color: $dark_text_color;
      font-weight: bold;
      text-shadow: none;
      width: 90%;
      height: auto;
      line-height: inherit;
      padding: 5px 0 5px 10px;
      margin-bottom: 10px;

      .jstree-icon {
        padding-left: 0px;
        @extend .fa !optional;
        @extend .fa-arrows !optional;
      }
    }
  }
}

#vakata-dragged.jstree-apple .jstree-invalid,
#vakata-dragged.jstree-apple .jstree-ok,
#jstree-marker {
  background-image: none !important;
  background-color: transparent !important;
  @extend [class^="icon-"]:before !optional;
}
#vakata-dragged.jstree-apple .jstree-invalid {
  @extend .fa !optional;
  @extend .fa-bars !optional;
  color: $gray80;
}
#vakata-dragged.jstree-apple .jstree-ok {
  @extend .fa !optional;
  @extend .fa-check !optional;
  color: $gray120;
}

#jstree-marker {
  @extend .fa-caret-right !optional;
  color: $dark_text_color !important;
  width: 4px !important;
}

#jstree-marker-line {
  border-radius: $border-radius-default !important;
  height: 0px !important;
  margin-left: 5px !important;
  margin-top: -2px !important;
  border: none !important;
  border-bottom: 1px solid $dark_text_color !important;
  background-color: $gray230 !important;

  -webkit-box-shadow: none !important;
     -moz-box-shadow: none !important;
          box-shadow: none !important;

}

#vakata-contextmenu {
  background-color: $gray230 !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: $border-radius-default !important;

  &:before {
    content: '';
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $gray80;
    top: 0px;
    margin-top: -10px;
    left: 25px;
    z-index: 1;
  }

  a {
    color: $gray100 !important;
    line-height: inherit !important;
    padding: 5px 10px !important;
    margin: 0 !important;
    font-size: 90% !important;

    i {
      color: $gray100 !important;
    }

    &:hover {
      border-radius: $border-radius-default !important;
      background-color: $gray120 !important;
      border: none !important;
      -moz-box-shadow: none !important;
      -webkit-box-shadow: none !important;
      line-height: inherit !important;
      padding: 5px 10px !important;
      margin: 0 !important;
      color: $white !important;

      i {
        color: $white !important;
      }
    }
  }

  li:first-child a:hover:before {
    content: '';
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $gray80;
    top: 0px;
    margin-top: -10px;
    left: 25px;
    z-index: 1;
  }

  li.vakata-separator {
    display: none;
  }
}
