//
// Utility classes
// --------------------------------------------------


// Quick floats
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

// Toggling content
.hide {
  display: none;
}
.show {
  display: block;
}

// Visibility
.invisible {
  visibility: hidden;
}

// For Affix plugin
.affix {
  position: fixed;
}

// Clearing floats
.clearfix {
  @include clearfix();
}

// Accessible yet invisible text
.hide-text {
  @include hide-text();
}

// Uses box-sizing mixin, so must be defined here
.input-block-level {
  @include input-block-level();
}
